/*
COLORNAME
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: May 21, 2022
UPDATE DATE: Jul 24, 2024
REVISION: 1
NAME: index.scss
TYPE: Cascading Style Sheet
DESCRIPTION: ColorName | Find your color name!
PALETTE: -
*/

a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,dialog,div,dl,dt,em,embed,fieldset,figcaption,figure,font,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,hr,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,meter,nav,object,ol,output,p,pre,progress,q,rp,rt,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video,xmp{border:0;margin:0;padding:0;font-size:100%}html,body{height:100%}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}b,strong{font-weight:bold}img{color:transparent;font-size:0;vertical-align:middle;-ms-interpolation-mode:bicubic}ol,ul{list-style:none}li{display:list-item}table{border-collapse:collapse;border-spacing:0}th,td,caption{font-weight:normal;vertical-align:top;text-align:left}q{quotes:none}q:before,q:after{content:'';content:none}sub,sup,small{font-size:75%}sub,sup{line-height:0;position:relative;vertical-align:baseline}sub{bottom:-0.25em}sup{top:-0.5em}svg{overflow:hidden}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Regular.woff2') format('woff2'),
       url('./assets/font/Exo-Regular.woff') format('woff'),
       url('./assets/font/Exo-Regular.ttf') format('ttf'),
       url('./assets/font/Exo-Regular.eot') format('eot'),
       url('./assets/font/Exo-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Bold.woff2') format('woff2'),
       url('./assets/font/Exo-Bold.woff') format('woff'),
       url('./assets/font/Exo-Bold.ttf') format('ttf'),
       url('./assets/font/Exo-Bold.eot') format('eot'),
       url('./assets/font/Exo-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Light.woff2') format('woff2'),
       url('./assets/font/Exo-Light.woff') format('woff'),
       url('./assets/font/Exo-Light.ttf') format('ttf'),
       url('./assets/font/Exo-Light.eot') format('eot'),
       url('./assets/font/Exo-Light.svg') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

%anim{
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;
}

html{
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body{
  background: #FFF;
  color: #999;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  margin: 0;
  font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

a{
  text-decoration: none;
  color: #666;
  @extend %anim;

  &:hover{
    color: #333;
  }
}

// a[disabled]{
//   pointer-events: none;
//   opacity: .5;
// }

button,
input{
  display: block;
  width: 100%;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  font-size: 12px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  @extend %anim;

  // &:hover{}

  &:focus{
    outline: none;
  }
}

button{
  cursor: pointer;
  
  *{
    pointer-events: none;
  }
}

.Toastify__toast-container{
  bottom: 20px !important;
  // right: 21px !important;
  padding: 0 !important;

  .Toastify__toast{
    font-family: 'Exo', Helvetica, Arial, sans-serif !important;
    font-size: 12px;
    line-height: 16px;
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, .1);
    margin-top: 10px;
    margin-bottom: 0;
    padding: 15px 0 20px 0 !important;
    border-radius: 5px;
  
    .Toastify__close-button{
      width: 16px;
      margin: 0 10px 0 0;
    }

    .Toastify__progress-bar-theme--light{
      background: #999;
    }

    .Toastify__toast-body{
      padding: 0 10px 0 15px;
    }
  }
}

header{
  padding: 15px 20px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(255, 255, 255, .95);
  z-index: 3;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, .1);

  > ul{
    margin: -5px;
    overflow: auto;

    > li{
      padding: 5px;
      float: left;
      box-sizing: border-box;

      &:nth-child(1){
        width: 64%;

        > a{
          float: left;
          font-size: 20px;
          line-height: 40px;
          font-weight: bold;

          > svg{
            display: block;
            width: 164px;
            height: 40px;
          }
        }

        > .wrap-search{
          margin: 0 0 0 174px;
          position: relative;

          > .hash{
            position: absolute;
            top: 0;
            left: 0;
            height: 40px;
            width: 40px;
            text-align: center;
            line-height: 40px;
            background: #CCC;
            color: #FFF;
            border-radius: 5px 0 0 5px;
            font-size: 14px;
            @extend %anim;

            > svg{
              display: inline-block;
              vertical-align: middle;
            }
          }

          > input{
            border: 1px solid #CCC;
            height: 40px;
            border-radius: 5px;
            padding: 0 15px 0 80px;
            background: #FFF;
            font-size: 14px;

            &.has-val{
              padding: 0 40px 0 80px;
            }

            &:focus,
            &:hover{
              border: 1px solid #999;

              ~ .hash{
                background: #999;
              }
            }
          }

          > .clear,
          > .picker{
            position: absolute;
            right: 0;
            top: 0;
            width: 40px;
            height: 40px;
            font-size: 16px;
            color: #666;

            &:hover{
              color: #333;
            }

            > svg{
              width: 18px;
              height: 18px;
              display: inline-block;
              vertical-align: middle;
              margin: -5px 0 0 0;
            }
          }

          > .picker{
            right: auto;
            left: 40px;
          }
        }

        .colorpicker{
          // right: 0;
          margin-left: 114px;
        }
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4){
        width: 12%;
      }

      .colorpicker{
        position: absolute;
        margin: 5px 0 0 0;
      }

      > button{
        background: #666;
        color: #FFF;
        border-radius: 5px;
        height: 40px;
        padding: 0 10px;
        font-weight: bold;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover{
          background: #333;
        }

        span{
          display: block;
          font-size: 9px;
          font-weight: normal;
          margin: 0 0 1px 0;
        }

        > svg{
          display: inline-block;
          vertical-align: middle;
          margin: -3px 5px 0 0;
          width: 14px;
          height: 14px;
        }

        > .preview{
          display: inline-block;
          vertical-align: middle;
          width: 11px;
          height: 11px;
          border: 1px solid rgba(255, 255, 255, .5);
          margin: -2px 5px 0 0;
        }
      }
    }
  }
}

.pad-top{
  height: 70px;
}

ul.list-color{
  overflow: auto;
  margin: .5px;
  padding: 20px 20px 40px 20px;

  &.search-result{
    padding: 20px 20px 0 20px;
    margin: .5px .5px -21.5px .5px;

    > .big{
      .color-box.comparison{
        box-shadow: none;
        padding: 20px 20px 80px 20px;
      }
    }
  }

  &.list{
    > li{
      width: 100%;

      > .color-box{
        height: 100px;
        padding: 0;

        &:hover{
          transform: scale(1.015, 1.015);
        }

        > .detail-box{
          right: 50%;
        }
      }

      &.big{
        > .color-box{
          height: auto;
          padding: 220px 20px 20px 20px;
        }
      }
    }
  }

  > li{
    float: left;
    padding: .5px;
    box-sizing: border-box;
    width: 16.66%;
    @extend %anim;
    
    &.big{
      width: 100%;  
      
      > .color-box{
        padding: 220px 20px 20px 20px;
        box-shadow: 0 20px 20px -10px rgba(0, 0, 0, .25);
        z-index: 1;
        margin: 20px 0;

        &.comparison{
          // padding: 14% 0;
          padding: 80px 20px 20px 20px;
          margin: 0;
        }

        &:hover{
          transform: scale(1, 1);
        }

        > .detail-box{
          position: static;
          padding: 18px 20px 20px 20px;
          // margin: 20px;
          // bottom: 20px;
          // left: 20px;
          // right: 20px;

          > ul{
            &.pri-info{
              > li{
                font-size: 14px;
                line-height: 18px;
                
                &.naming{
                  font-size: 26px;
                  line-height: 30px;
                  font-weight: 100;

                  // &:hover{
                  //   svg{
                  //     width: 24px;
                  //     height: 24px;
                  //     margin: -3px 2.5px 0 0;
                  //   }
                  // }
                }
                
                // &:hover{
                //   svg{
                //     width: 14px;
                //     height: 14px;
                //     margin: -3px 2.5px 0 0;
                //   }
                // }
              }
            }

            &.sec-info{
              display: block;
              overflow: auto;
              margin: 8px -5px -5px -5px;

              > li{
                width: auto;
                padding: 5px;
                
                &:hover{
                  padding-left: 10px;
                }

                &.lay2{
                  float: left;
                  width: 50%;
                  box-sizing: border-box;
                  
                  &:hover{
                    padding-left: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }

    > .color-box{
      position: relative;
      z-index: 0;
      padding: 50% 0;
      cursor: pointer;
      @extend %anim;

      &:hover{
        z-index: 2;
        transform: scale(1.1, 1.1);
        box-shadow: 0 20px 20px -10px rgba(0, 0, 0, .25);
      }

      > .detail-box{
        position: absolute;
        bottom: 10px;
        left: 10px;
        right: 10px;
        padding: 8px 10px 10px 10px;
        background: rgba(0, 0, 0, .25);

        > ul{
          margin: -1px -5px;

          &.pri-info{
            > li{
              &.naming{
                font-size: 16px;
                line-height: 20px;
                font-weight: normal;

                // &:hover{
                //   svg{
                //     width: 16px;
                //     height: 16px;
                //     margin: -3px 2.5px 0 0;
                //   }
                // }
              }

              span{
                display: none;
              }
            }
          }

          > li{
            padding: 1px 5px;
            color: #FFF;
            font-weight: bold;
            font-size: 10px;
            line-height: 14px;
            display: inline-block;
            width: 100%;

            color: #FFF;
            font-size: 10px;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
            @extend %anim;

            span{
              pointer-events: none;
              display: block;
              font-weight: normal;
            }

            &:hover{
              padding-left: 12.5px;
            }
            
            // &:hover{
            //   svg{
            //     margin: -1px 2.5px 0 0;
            //     width: 10px;
            //     height: 10px;
            //   }
            // }

            // svg{
            //   display: inline-block;
            //   vertical-align: middle;
            //   width: 0;
            //   height: 0;
            //   transition: all 200ms ease-in-out;
            //   -moz-transition: all 200ms ease-in-out;
            //   -webkit-transition: all 200ms ease-in-out;
            // }

            .box-css{
              width: 9px;
              height: 9px;
              border: 1px solid rgba(255, 255, 255, .5);
              display: inline-block;
              vertical-align: middle;
              margin: -1px 3px 0 0;
            }
          }
        }

        .hue-picker{
          box-shadow: 0 0 0 1px rgba(255, 255, 255, .5);
          margin: 5px 0 0 0;
          width: 100% !important;
          height: 15px !important;
          box-sizing: border-box;

          > div{
            border-radius: 0 !important;

            > div{
              border-radius: 0 !important;
            }
          }

          .pointer{
            margin: -4px 0 0 0;
            height: 23px;
            width: 1px;
            background: #FFF;
            transform: translate(-.5px, 0);
          }
        }

        .darkpicker{
          position: relative;
          width: 100%;
          height: 15px;
          box-sizing: border-box;
          margin: 5px 0 0 0;
          box-shadow: 0 0 0 1px rgba(255, 255, 255, .5);
          /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,ffffff+100 */
          background: #000000; /* Old browsers */
          background: -moz-linear-gradient(left,  #000000 0%, #ffffff 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(left,  #000000 0%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to right,  #000000 0%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */

          &.huepicker{
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff0000+0,ffff00+17,00ff00+33,00ffff+50,0000ff+67,ff00ff+83,ff0000+100 */
            background: rgb(255,0,0); /* Old browsers */
            background: -moz-linear-gradient(left,  rgba(255,0,0,1) 0%, rgba(255,255,0,1) 17%, rgba(0,255,0,1) 33%, rgba(0,255,255,1) 50%, rgba(0,0,255,1) 67%, rgba(255,0,255,1) 83%, rgba(255,0,0,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  rgba(255,0,0,1) 0%,rgba(255,255,0,1) 17%,rgba(0,255,0,1) 33%,rgba(0,255,255,1) 50%,rgba(0,0,255,1) 67%,rgba(255,0,255,1) 83%,rgba(255,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  rgba(255,0,0,1) 0%,rgba(255,255,0,1) 17%,rgba(0,255,0,1) 33%,rgba(0,255,255,1) 50%,rgba(0,0,255,1) 67%,rgba(255,0,255,1) 83%,rgba(255,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0000', endColorstr='#ff0000',GradientType=1 ); /* IE6-9 */
          }
          
          .pointer{
            position: absolute;
            margin: -4px 0 0 0;
            height: 23px;
            width: 1px;
            background: #FFF;
            transform: translate(-.5px, 0);
          }
        }

        .colorchart{
          margin: -5px;
          overflow: auto;

          &.cmyk{
            > li{
              width: 25%;
            }
          }

          > li{
            float: left;
            width: 33.33%;
            padding: 5px;
            box-sizing: border-box;
            font-weight: normal;
            font-size: 10px;

            &:last-child{
              width: 100%;
            }

            > div{
              position: relative;
              margin: 5px 0 0 0;
              height: 15px;
              background: rgba(255, 255, 255, .5);
              box-shadow: 0 0 0 1px rgba(255, 255, 255, .5);

              > span{
                display: block;
                // margin: 1px 0 0 0;
                height: 15px;
                background: #FFF;
              }

              &.multi{
                > span{
                  display: inline-block;
                  vertical-align: top;
                }
              }
            }
          }
        }
      }
    }
  }
}

.empty{
  text-align: center;
  padding: 100px 20px;
  line-height: 16px;

  > svg{
    display: block;
    font-size: 90px;
    margin: 0 auto 10px auto;
  }
}

footer{
  text-align: center;
  padding: 0 40px 40px 40px;
  font-size: 11px;
  line-height: 15px;

  a{
    font-weight: bold;

    &:hover{
      margin: 0 5px;
    }

    > svg{
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      margin: -3px 0 0 0;
    }
  }

  > div{
    margin: 2.5px 0 0 0;
    font-size: 10px;
    line-height: 14px;
  }
}

.sketch-picker{
  width: 180px !important;
  position: relative;
  border-radius: 5px !important;
  border: 1px solid #CCC;
  // box-shadow: none ;
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, .25) !important;
  z-index: 4;
  padding: 10px 10px 5px 10px !important;

  input{
    border-radius: 5px;
    width: 100% !important;
    text-align: center;
    height: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
  header{
    > ul{
      > li{
        &:nth-child(1){
          width: 52%;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4){
          width: 16%;
        }
      }
    }
  }

  ul.list-color{
    > li{
      width: 20%;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
  header{
    > ul{
      > li{
        &:nth-child(1){
          width: 49%;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4){
          width: 17%;
        }
      }
    }
  }

  ul.list-color{
    > li{
      width: 25%;
    }
  }
}

@media screen and (max-width: 767px){
  ul.list-color{
    > li{
      &.big{
        > .color-box{
          > .detail-box{
            > ul.sec-info{
              > li.lay2{
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) and (orientation: landscape){
  header{
    > ul{
      > li{
        &:nth-child(1){
          width: 37%;
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4){
          width: 21%;
        }
      }
    }
  }

  ul.list-color{
    > li{
      width: 25%;
    }
  }

  footer{
    padding: 0 20px 40px 20px;
  }
}

@media screen and (max-width: 767px) and (orientation: portrait){
  header{
    > ul{
      > li{
        &:nth-child(1){
          width: 100%;

          > a{
            font-size: 16px;

            > svg{
              width: 113px;
            }
          }

          > .wrap-search{
            margin: 0 0 0 123px;
          }
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4){
          width: 33.33%;
        }
      }
    }
  }

  .pad-top{
    height: 120px;
  }
  
  ul.list-color{
    padding: 20px;

    &.list{
      > li{
        > .color-box{
          > .detail-box{
            right: 35%;
          }
        }
      }
    }

    > li{
      width: 50%;

      .color-box{
        > .detail-box{
          .colorchart{
            > li{
              width: 100%;
            }

            &.cmyk{
              > li{
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .Toastify__toast-container{
    width: auto !important;
    left: 30px !important;
    right: 30px !important;

    .Toastify__toast{
      border-radius: 5px;
    }
  }
  
  footer{
    padding: 0 20px 20px 20px;
  }
}

/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}
*/